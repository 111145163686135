import React from "react";
import { useState, useEffect } from "react";
import { useStylesPreviewVoucher } from "../../styles/stepStyles";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  setStepsVoucherCreationParams,
  setDesktopUserLoginFormStatus,
} from "../../../../redux/actions/actions";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../../../i18n-js/index.js.erb";
import HotelBreakLoader from "../../../components/loaders/hotelBreakLoader";
import Preview from "./Preview"
import { Cookies, useCookies } from "react-cookie";

import { Form, Button } from "reactstrap";

function PreviewVoucher(props) {
  const BASE_URL = props.baseUrl || '';
  const dispatch = useDispatch();
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );
  const [cookies, setCookie] = useCookies(['variant'])
  const currentUser = useSelector((state) => state.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    errors,
    control,
  } = useForm();

  const useStyles = makeStyles((theme) => ({
    customDesign: {
      width: "100% !important",
      maxWidth: "100% !important",
      height: "75% !important",
      margin: "0 auto 5px auto !important",
      backgroundImage: `url(${stepsVoucherCreationParams.step3.custom.url})`,
      borderRadius: "5px",
      padding: "20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  }));

  useEffect(() => {
    if (!stepsVoucherCreationParams.step5.status) {
      axios
        .post(`${BASE_URL}/api/main/tracking/checkouts/viewed.json`, {
          params: { step: 5, step_name: "previsualize", version: cookies["variant"] },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) { });
    }

    !currentUser.id &&
      dispatch(
        setDesktopUserLoginFormStatus({
          open: true,
          selectRegister: true,
          notClosable: true,
        })
      );
  }, []);

  const localClasses = useStyles();

  const onSubmit = (data) => {
    setIsLoading(true);

    axios
      .post(`${BASE_URL}/api/main/gift_steps/new.json`, { ...stepsVoucherCreationParams })
      .then((response) => {
        dispatch(
          setStepsVoucherCreationParams({
            ...stepsVoucherCreationParams,
            step5: {
              status: true,
              code: response.data.voucher_code,
              payment_intent: response.data.payment_intent,
            },
          })
        );

        props.handleClientSecret(response.data.client_secret);

        axios
          .post(`${BASE_URL}/api/main/tracking/checkouts/completed.json`, {
            params: { step: 5, step_name: "previsualize", version: cookies["variant"] },
          })
          .then((response) => {
            console.log(response.status);
          })
          .catch(function (error) { });

        props.handleNext();
      })
      .catch(function (error) {
        console.log("check error", error);
      });
  };

  const classes = useStylesPreviewVoucher();

  return (
    <div>
      {isLoading ? (
        <HotelBreakLoader />
      ) : (
        <>
          <Preview />
          <br />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Button className={classes.fullWidth} type="submit">
              {I18n.t("new_gift__preview_button")}
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}

export default PreviewVoucher;
