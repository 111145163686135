import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import I18n from "../../../../i18n-js/index.js.erb";
import MailCheck from "react-mailcheck";
import libphonenumbers from "libphonenumbers";
import React, { useState } from "react";
import { FormGroup, Label, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCurrentUser, setPaymentData } from "../../../redux/actions/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { DevTool } from "@hookform/devtools";
import clsx from "clsx";

const StyledCircularProgress = withStyles({
  root: {
    marginTop: "3rem",
  },
})(CircularProgress);

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

function TravelAgentUserForm(props) {
  const BASE_URL = props.baseUrl || '';
  const dispatch = useDispatch();
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentUser = useSelector((state) => state.currentUser);
  const FunnelData = useSelector((state) => state.PaymentData);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;


  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [loader, setLoader] = useState(false);
  const FORM_SOURCE = "Reservation funnel user registration";

  const schema = yup.object({
    reservation_email: yup
      .string()
      .email(I18n.t("reservation_email_format"))
      .required(I18n.t("is_required")),
    reservation_name: yup.string().required(I18n.t("is_required")),
    reservation_lastname: yup.string().required(I18n.t("is_required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const useStyles = makeStyles((theme) => ({
    eyeLink: {
      position: "absolute",
      top: "56%",
      right: "5%",
      cursor: "pointer",
    },
    email_suggestion_link: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    form_style: {
      width: "100% !important",
      borderRadius: "2px !important",
      height: "41px !important",
      padding: "6px 12px 6px 48px !important",
    },
    label: {
      fontWeight: 800,
    },
    gdprLabel: {
      marginTop: "2px",
      maxWidth: "90%",
      marginLeft: "3%",
      display: "inline-block",
    },
    primaryColor: {
      backgroundColor: "#0098d7",
      color: "#000 !important",
      "& *": {
        color: "#000 !important",
      },
      border: "1px solid #0098d7",
      borderRadius: "2px",
      fontWeight: "bold",
      padding: "10px 25px 10px 25px",
      "&.Mui-disabled": {
        filter: "opacity(0.5)",
      },
      "&:hover": {
        backgroundColor: "#0098d7",
        border: "1px solid #0098d7",
      },
    },
    widgetHidden: {
      display: "inherit",
    },
  }));

  const classes = useStyles();

  const [phoneError, setPhoneError] = useState(false);

  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const isValid = (value, country) => {
    const countryUp = country.toUpperCase();
    if (phoneUtil.parseAndKeepRawInput(value, countryUp)) {
      const number = phoneUtil.parseAndKeepRawInput(value, countryUp);
      if (!phoneUtil.isValidNumberForRegion(number, countryUp)) {
        setPhoneError(true);
        analyticsTrack("Filled reservation_phone", {
          input: value,
          country: country,
          validation: "passed",
        });
      } else {
        setPhoneError(false);
        analyticsTrack("Filled reservation_phone", {
          input: value,
          country: country,
          validation: "Not passed isValidNumberForRegion",
        });
      }
    } else {
      setPhoneError(false);
      analyticsTrack("Filled reservation_phone", {
        input: value,
        country: country,
        validation: "Not passed parseAndKeepRawInput",
      });
    }
  };

  I18n.locale = currentLocale;

  const onSubmit = (data) => {
    setLoader(true);
    if (phoneError) {
      setLoader(false);

      return false;
    }

    axios
      .post(`${BASE_URL}/api/main/funnel/travel_agent_users`, {
        travel_agent_email: currentUser.email,
        reservation_email: data.reservation_email,
        reservation_phone: phone,
        reservation_code: reservation_code,
        reservation_name: data.reservation_name,
        reservation_lastname: data.reservation_lastname,
        id_number: data.reservation_dni,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setAlreadyRegistered(true);
          dispatch(
            setCurrentUser({
              ...response.data.current_user.data.attributes,
              clientUserDataUp: true,
              client_user: response.data.client_user.data.attributes,
            })
          );
        }
      })
      .catch(function (error) {
        setLoader(false);
        dispatch(
          setPaymentData({
            ...FunnelData,
            log_in_form: true,
            used_email: data.reservation_email,
          })
        );
      });
  };

  return (
    <div>
      <React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 no-padding">
            <div className="col-md-6 no-padding-6-l">
              <Label htmlFor="reservation_name" className={classes.label}>
                {I18n.t("form_name")}
              </Label>
              <input
                type="text"
                id="reservation_name"
                name="reservation_name"
                {...register("reservation_name")}
                className="form-control"
                placeholder={I18n.t("form_name_ex")}
                autoFocus="true"
                required
                minLength="3"
                onBlur={(ev) =>
                  analyticsTrack("Filled reservation_name", {
                    input: ev.target.value,
                  })
                }
                onFocus={(ev) =>
                  analyticsTrack("Entered reservation_name", {
                    input: ev.target.value,
                  })
                }
              />
            </div>
            <div className="col-md-6 no-padding-6-r">
              <Label htmlFor="reservation_lastname" className={classes.label}>
                {" "}
                {I18n.t("form_last_name")}
              </Label>
              <input
                type="text"
                name="reservation_lastname"
                id="reservation_lastname"
                {...register("reservation_lastname")}
                className="form-control"
                placeholder={I18n.t("form_last_name_ex")}
                required
                minLength="3"
                onBlur={(ev) =>
                  analyticsTrack("Filled reservation_lastname", {
                    input: ev.target.value,
                  })
                }
                onFocus={(ev) =>
                  analyticsTrack("Entered reservation_lastname", {
                    input: ev.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-12 no-padding">
            <div className="col-md-6 no-padding-6-l">
              <Label htmlFor="reservation_email" className={classes.label}>
                {I18n.t("form_email")}
              </Label>
              <MailCheck email={email}>
                {(suggestion) => (
                  <div>
                    <input
                      type="email"
                      value={email}
                      id="reservation_email"
                      name="reservation_email"
                      onChange={(ev) => setEmail(ev.target.value)}
                      className="form-control"
                      {...register("reservation_email")}
                      placeholder={I18n.t("form_email")}
                      onBlur={(ev) =>
                        analyticsTrack("Filled reservation_email", {
                          input: ev.target.value,
                        })
                      }
                      onFocus={(ev) =>
                        analyticsTrack("Entered reservation_email", {
                          input: ev.target.value,
                        })
                      }
                    />
                    {suggestion && (
                      <div>
                        {I18n.t("do_you_mean")}{" "}
                        <span
                          className={classes.email_suggestion_link}
                          onClick={(ev) => setEmail(suggestion.full)}
                        >
                          {suggestion.full}
                        </span>
                        ?
                      </div>
                    )}
                  </div>
                )}
              </MailCheck>
            </div>
          </div>
          <div className="col-md-12 no-padding">
            <Col className="col-md-6 no-padding-6-l">
              <FormGroup>
                <Label
                  htmlFor="reservation_phone"
                  id="phoneLabel"
                  className={clsx(phoneError ? "error" : "", classes.label)}
                >
                  {I18n.t("form_phone")}
                </Label>
                <PhoneInput
                  country={props.locale}
                  value={phone}
                  onChange={setPhone}
                  onBlur={(e, country) =>
                    isValid(e.target.value, country.countryCode)
                  }
                  onFocus={(ev) =>
                    analyticsTrack("Entered reservation_phone", {
                      input: ev.target.value,
                    })
                  }
                  placeholder={I18n.t("form_phone")}
                  countryCodeEditable={false}
                  inputProps={{
                    name: "reservation_phone",
                    required: true,
                    id: "reservation_phone",
                  }}
                  inputClass={classes.form_style}
                  containerClass={classes.phone_container}
                />
                <p className="error">{errors.phone?.message}</p>
                {phoneError && <p className="error">{I18n.t("wrong_phone")}</p>}
              </FormGroup>
            </Col>
            <Col className="col-md-6 no-padding-6-r">
              {currentUser.isInformationComplete.country === "ESP" && (
                <>
                  <Label htmlFor="reservation_dni" className={classes.label}>
                    {I18n.t("identity_number")}
                  </Label>
                  <input
                    type="text"
                    name="reservation_dni"
                    id="reservation_dni"
                    {...register("reservation_dni")}
                    onBlur={(ev) =>
                      analyticsTrack("Filled reservation_dni", {
                        input: ev.target.value,
                      })
                    }
                    onFocus={(ev) =>
                      analyticsTrack("Entered reservation_dni", {
                        input: ev.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="ej: 13149093E"
                    length="7"
                    required
                  />
                  <br />
                </>
              )}
            </Col>
          </div>
          <div className="col-md-12 no-padding">
            <br />
            <p>
              <input
                type="checkbox"
                name="gdpr"
                id="gdpr"
                onClick={(ev) =>
                  analyticsTrack("Checked gdpr", {
                    checked: ev.target.checked,
                  })
                }
              />
              <label htmlFor="gdpr" className={classes.gdprLabel}>
                {I18n.t("gdpr_sentence")}
              </label>
            </p>
          </div>
          <div className="col-md-12 text-right no-padding">
            {loader && <StyledCircularProgress />}
            {!loader && (
              <button
                className={clsx("btn", classes.primaryColor)}
                type="submit"
                onClick={() => analyticsTrack("Continue to payment")}
              >
                {I18n.t("payment_form__continue_button")}{" "}
              </button>
            )}
            {!loader && alreadyRegistered && (
              <p className="error">{alreadyRegistered}</p>
            )}
          </div>
        </form>
        <DevTool control={control} />
      </React.Fragment>
    </div>
  );
}

export default TravelAgentUserForm;
